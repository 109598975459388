import React from 'react';
import { InnerPageLayout } from 'components/InnerPageLayout';
import { graphql } from 'gatsby';
import GatsbyImg from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from 'components/SEO';
import { css } from '@emotion/core';
import Container from 'components/Container';
import { Boilerplate } from '../components/Boilerplate';
import { fonts } from '../lib/typography';
import config from '../../config/website';
import { bpMaxSM } from '../lib/breakpoints';

export default function Post({
  data: { site, mdx },
  pageContext: { next, prev },
}) {
  const author = mdx.frontmatter.author || config.author;
  const date = mdx.frontmatter.date;
  const title = mdx.frontmatter.title;
  const banner = mdx.frontmatter.banner;

  return (
    <InnerPageLayout site={site}>
      <SEO frontmatter={mdx.frontmatter} isBlogPost />
      <article>
        <h1
          css={css`
            text-align: left;
            margin-bottom: 20px;
          `}
        >
          {title}
        </h1>
        <div
          css={css`
            display: flex;
            justify-content: left;
            margin-bottom: 3rem;
            h3,
            span {
              text-align: left;
              font-size: 15px;
              opacity: 0.6;
              font-family: ${fonts.regular}, sans-serif;
              font-weight: normal;
              margin: 0 5px;
            }
          `}
        >
          {/* {author && <h3>{author}</h3>}
          {author && <span>—</span>} */}
          {date && <h3>{date}</h3>}
        </div>

        {banner && (
          <GatsbyImg
            css={css`
              margin-bottom: 3rem;
            `}
            fluid={banner.childImageSharp.fluid}
          />
        )}
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
    </InnerPageLayout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...site
    }
    mdx(fields: { id: { eq: $id } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        # author
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        slug
        keywords
      }
      body
    }
  }
`;
